<template>
  <div>
    <AudioWrapper
      v-for="(link, index) in links"
      :key="index"
      :ref="link.paraId"
      :data-para-id="link.paraId"
      :src="link.src"
      @error="audioPlayerErrorHandler"
      @timeupdate.native="onTimeUpdate"
      @ended.native="ended"
    />
  </div>
</template>
<script>
/**
 * All the incoming and outcoming time values are expected to be in milliseconds (NOT seconds).
 */
import Utils from '@/services/utils/Utils';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('AudioPlayer.vue');
import get from 'lodash/get';
import pick from 'lodash/pick';
import isUndefined from 'lodash/isUndefined';
import AudioPlayerEvents from '@/enums/AudioPlayerEvents';
import CustomErrorEnum from '@/enums/CustomErrorEnum';
import LinkFactory from '@/classes/factories/LinkFactory';
import Locator from '@shared/publication/locator.mjs';
import AppConstants from '@/services/utils/AppConstantsUtil';
import PromiseUtil from '@/services/utils/PromiseUtil';
import AudioWrapper from '@/components/views/Audio/AudioWrapper';

export default {
  name: 'AudioPlayer',
  components: {
    AudioWrapper
  },
  data() {
    return {
      needStopOnEnd: false,
      prepareAudioDefer: null,
      loadAudioDefer: null,
      isDestroyed: false,
      raf: null,
      endSubscription: null,
      timeoutBetweenParas: null,
      stopAudioTimeout: null,
      initialTime: 0,
      audioStopTime: 0,
      meta: null,
      isAudioControlsVisible: false,
      links: []
    };
  },
  computed: {
    publicationId() {
      return this.$store.getters['OpenParameterStore/getPublicationId'];
    },
    isAudioPaused() {
      return this.$store.getters['PlaybackStore/isAudioPaused'];
    },
    isAudioPlaying() {
      return this.$store.state.PlaybackStore.isPlaying;
    },
    playbackRate() {
      return this.$store.getters['ReadingSettingsStore/getPlaybackRate'];
    },
    currentParaId() {
      return this.$store.state.PlaybackStore.currentParaId;
    },
    isIos() {
      return this.$store.getters['ContextStore/isIos'];
    },
    isSafari() {
      return this.$store.getters['ContextStore/isSafari'];
    },
    isAndroid() {
      return this.$store.getters['ContextStore/isAndroid'];
    },
    isAppInBackground() {
      return this.$store.getters['ContextStore/isAppInBackground'];
    },
    isAndroidBackground() {
      return this.isAndroid && this.isAppInBackground;
    },
    isWebBackground() {
      return this.$store.getters['ContextStore/isWebBackground'];
    },
    alignmentIndex() {
      return this.$store.getters['PublicationStore/getParagraphAlignment'](
        this.publicationId,
        this.currentParaId
      );
    },
    paraId() {
      return this.$store.getters['PublicationStore/getFirstAudioParagraph']();
    },
    playSinglePara() {
      return this.$store.getters['PlaybackStore/getPlaySinglePara'];
    }
  },
  watch: {
    isWebBackground() {
      this.onVisibilityChange();
    },
    playbackRate() {
      const currentAudioElement = this.getCurrentAudioElement();
      if (currentAudioElement) {
        currentAudioElement.playbackRate = this.playbackRate;
      }
    },
    async publicationId(newPubId) {
      await this.$_loadPublicatioAudioAssets(newPubId);
    },
    async currentParaId(curParaId) {
      await this.$_prepareAudio(curParaId);
    }
  },
  created() {
    const preloadCount = this.$store.getters['PlaybackStore/getPreloadCount'];
    this.links = Array.from({ length: preloadCount }, () =>
      this.$_createEmptyLink()
    );
  },
  async mounted() {
    await this.$_loadPublicatioAudioAssets(this.publicationId);
    if (this.currentParaId) {
      await this.$_prepareAudio(this.currentParaId);
    }

    try {
      this.endSubscription = this.$store.subscribeAction({
        before: mutation => {
          switch (mutation.type) {
            case 'PlaybackStore/continue':
              this.$_setIsUserPlayAudio(true);
              this.continue();
              break;
            case 'PlaybackStore/playFromLocator':
              this.$_setIsUserPlayAudio(true);
              this.playFromLocator(
                mutation.payload.locator,
                mutation.payload.endLocator
              );
              break;
            case 'PlaybackStore/pause':
            case 'AnnotationsStore/playAnnotation':
              this.$_setIsUserPlayAudio(false);
              this.pause(mutation.payload);
              break;
            case 'PlaybackStore/next':
              this.next(mutation.payload);
              break;
          }
        },
        after: mutation => {
          switch (mutation.type) {
            case 'PlaybackStore/playParagraph': {
              this.$_setIsUserPlayAudio(true);
              const time = mutation.payload.time || this.$_getCurrentTime();
              this.playParagraph(mutation.payload.paraId, time);
              break;
            }
          }
        }
      });

      this.$store.commit('PlaybackStore/setCurrentBookId', this.publicationId);
      this.$store.commit('PlaybackStore/setCurrentParaId', this.paraId);
      this.meta = this.getMeta();
      this.emit(AudioPlayerEvents.MOUNTED);
    } catch (error) {
      this.audioPlayerErrorHandler(error);
    }
  },
  destroyed() {
    if (this.endSubscription) {
      this.endSubscription();
    }
    this.pause();
    this.isDestroyed = true;
  },
  methods: {
    getCurrentAudioElement() {
      return get(this.$refs, `[${this.currentParaId}][0]`, null);
    },
    async $_prepareAudio(curParaId) {
      if (!curParaId) {
        return;
      }
      try {
        if (!this.prepareAudioDefer?.promise) {
          this.prepareAudioDefer = PromiseUtil.createDeferred();
        }
        if (this.loadAudioDefer?.promise) {
          await this.loadAudioDefer.promise;
        }
        this.loadAudioDefer = PromiseUtil.createDeferred();
        if (!curParaId) {
          return;
        }
        let links = {};
        const audioLinks = this.$store.getters[
          'PublicationStore/getAudioLinks'
        ]();
        const preloadCount = this.$store.getters[
          'PlaybackStore/getPreloadCount'
        ];
        if (audioLinks && curParaId) {
          const keys = Object.keys(audioLinks);
          const index = keys.indexOf(curParaId);
          links = pick(audioLinks, keys.slice(index, preloadCount + index));
        }
        const linkKeys = Object.keys(links);
        let index = 0;
        const audioSourcesPromises = [];
        for (const paraId of linkKeys) {
          let link = this.links[index];
          if (!link) {
            link = LinkFactory.createLink('', paraId);
            this.links.push(link);
          }
          link.setParaId(paraId);
          const loadAudioPromise = this.$_setAudioSrc(link);
          if (paraId === curParaId) {
            loadAudioPromise.then(() => {
              if (this.prepareAudioDefer?.resolve) {
                this.prepareAudioDefer.resolve();
              }
              this.prepareAudioDefer = null;
            });
          }
          audioSourcesPromises.push(loadAudioPromise);
          index += 1;
        }
        await Promise.all(audioSourcesPromises);
        index = 0;

        for (let i = linkKeys.length; i < this.links.length; i++) {
          const link = this.links[i];
          if (link.resetToEmpty) {
            link.resetToEmpty();
          }
        }
        await this.$_prepareAllAudioElement(curParaId);
      } catch (error) {
        logger.error(`Get error on create links error: ${error}`);
      }
      if (this.loadAudioDefer?.resolve) {
        this.loadAudioDefer.resolve();
      }
      this.loadAudioDefer = null;
    },
    async $_loadPublicatioAudioAssets(publicationId) {
      try {
        if (!publicationId) {
          return;
        }
        await this.$store.dispatch('PublicationStore/initPublicationAudio', {
          publicationId
        });
      } catch (error) {
        logger.fatal(
          `get error on load publication audio assets error: ${error.stack}`
        );
        return;
      }
    },
    continue() {
      this.playParagraph(
        this.$store.state.PlaybackStore.currentParaId,
        this.$store.state.PlaybackStore.currentTime
      );
    },
    async $_setAudioSrc(audioLink) {
      let newSrc = '';
      try {
        newSrc = await this.$store.dispatch(
          'PublicationStore/getAudioSourceLink',
          {
            publicationId: this.publicationId,
            paraId: audioLink.paraId
          }
        );
      } catch (error) {
        const message = get(error, 'message', '');
        const isNetworkError = message.includes('Network Error');
        if (isNetworkError) {
          this.audioPlayerErrorHandler(CustomErrorEnum.NETWORK_ERROR);
          return;
        }
        logger.error(error);
      }
      audioLink.setSrc(newSrc);
    },
    onVisibilityChange() {
      const currentAudioElement = this.getCurrentAudioElement();

      if (!this.isWebBackground && currentAudioElement && this.isAudioPlaying) {
        const currentTime =
          currentAudioElement.getCurrentTime() * AppConstants.MS_IN_SEC;
        this.$_stopRAFLoop();
        this.$_startRAFLoop(currentTime);
      }
    },
    $_setIsUserPlayAudio(val) {
      this.$store.commit('PlaybackStore/setIsUserPlayAudio', val);
    },
    $_createEmptyLink() {
      return LinkFactory.createEmptyLink();
    },
    $_getCurrentTime() {
      return this.$store.getters['PlaybackStore/getCurrentTime'];
    },
    async preloadAlignmentBeforePlay(paraId) {
      this.pause();
      try {
        await this.$store.dispatch('PlaybackStore/preloadAlignment', {
          paraId,
          bookId: this.publicationId
        });
      } catch (err) {
        throw CustomErrorEnum.NETWORK_ERROR;
      } finally {
        this.$store.commit('PlaybackStore/setCurrentParaId', paraId);
      }
    },
    async playFromLocator(locator, endLocator) {
      const paraId = locator.startLocator.prefixedParagraphId;
      try {
        await this.preloadAlignmentBeforePlay(paraId);
        const timeByLocator = this.$store.getters[
          'PublicationStore/getTimeByLocator'
        ](this.publicationId, locator.startLocator);

        const endTime = this.$_getEndTimeByLocator(locator, endLocator);
        this.playParagraph(paraId, timeByLocator, endTime);
      } catch (error) {
        this.audioPlayerErrorHandler(error);
      }
    },

    $_getEndTimeByLocator(locator, endLocator) {
      if (!endLocator) {
        return null;
      }
      const isMultipleParaSelected = !locator.startLocator.equalsByBasis(
        endLocator
      );
      if (isMultipleParaSelected) {
        return this.$_getCurrentParaAlignmentEnd();
      }
      return this.$store.getters['PublicationStore/getEndTimeByLocator'](
        this.publicationId,
        endLocator
      );
    },

    async playParagraph(paraId, time, endTime) {
      try {
        await this.preloadAlignmentBeforePlay(paraId);
        if (!time) {
          time = await this.$store.dispatch('PlaybackStore/calcCurrentTime', {
            paraId
          });
        }
        await this.$_waitPositionHandlers();
        if (!this.isAudioPaused) {
          this.$_playOnAlignmentReady(time, endTime);
        }
      } catch (error) {
        this.audioPlayerErrorHandler(error);
      }
    },

    $_waitPositionHandlers() {
      return PromiseUtil.wait(100);
    },

    $_prepareAllAudioElement(curParaId) {
      const audioElements = Object.keys(this.$refs).reduce(
        (elements, paraId) => {
          const ref = this.$refs[paraId];
          if (paraId !== curParaId && ref[0]) {
            elements.push(ref[0]);
          }
          return elements;
        },
        []
      );
      const promises = [...audioElements].map(audioElement => {
        return audioElement.$_prepareAudioElement().catch(err => {
          logger.error(
            `Get error on load audio src: ${audioElement?.src} error: ${err}`
          );
        });
      });
      return Promise.all(promises);
    },
    async $_playOnAlignmentReady(time, endTime) {
      if (this.prepareAudioDefer) {
        await this.prepareAudioDefer.promise;
      }
      const currentAudioElement = this.getCurrentAudioElement();
      if (!currentAudioElement || currentAudioElement?.checkErrors()) {
        return;
      }
      if (!isUndefined(time)) {
        let duration = currentAudioElement.getDuration();
        const isNeedWaitAudioLoaded = isNaN(duration);
        if (isNeedWaitAudioLoaded) {
          duration = await Utils.waitDuration(currentAudioElement);
        }
        const stepBackFromTheEnd = 10;
        const durationEndTime = duration
          ? duration * AppConstants.MS_IN_SEC - stepBackFromTheEnd
          : Infinity;
        const initialTime = Math.min(time, durationEndTime);
        this.initialTime = initialTime;
        const startTime =
          time >= duration * AppConstants.MS_IN_SEC ? initialTime : time;
        await currentAudioElement.setCurrentTime(
          startTime / AppConstants.MS_IN_SEC
        );
      }
      this.needStopOnEnd = Boolean(endTime);
      currentAudioElement.playbackRate = this.playbackRate;

      this.$_cleanStopAudioTimeout();
      this.$_initStopAudioTimeout(time, endTime);
      this.$_startRAFLoop(time);
      this.clearPauseTimeout();
      await currentAudioElement.play();
      this.$store.commit('PlaybackStore/setIsPlaying', true);
    },

    $_initStopAudioTimeout(time, endTime) {
      if (!this.alignmentIndex) {
        return;
      }
      const end = endTime || this.$_getCurrentParaAlignmentEnd();
      const audioPlayTime = Math.max(end - time, 0);
      this._runStopAudioTimeout(audioPlayTime, end);
    },

    $_getCurrentParaAlignmentEnd() {
      const audioOffsets = this.alignmentIndex[0];
      return audioOffsets[audioOffsets.length - 1][1];
    },

    _runStopAudioTimeout(audioDuration, endTime, count = 0) {
      count += 1;
      const accuracy = 10;
      this.stopAudioTimeout = setTimeout(() => {
        const currentAudioElement = this.getCurrentAudioElement();
        if (!currentAudioElement) {
          return;
        }
        this.audioStopTime = new Date().getTime();
        const currentTime =
          currentAudioElement.getCurrentTime() * AppConstants.MS_IN_SEC;
        const deltaOnEnd = endTime - currentTime;

        const tooManyAttempt = count > 5;

        if (deltaOnEnd > accuracy && !tooManyAttempt) {
          this._runStopAudioTimeout(deltaOnEnd, endTime, count);
          return;
        } else if (tooManyAttempt) {
          logger.error(
            `too many attempt for stop audio bookId:${this.publicationId}, currentParaId:${this.currentParaId}, accuracy: ${accuracy}, deltaOnEnd:${deltaOnEnd}`
          );
        }
        if (this.needStopOnEnd) {
          this.pause();
          return;
        }
        this.$_onEnd();
        this.$store.commit('PlaybackStore/setTimeToStop', null);
      }, audioDuration);
    },

    $_cleanStopAudioTimeout() {
      if (this.stopAudioTimeout) {
        clearTimeout(this.stopAudioTimeout);
        this.stopAudioTimeout = null;
      }
    },
    pause() {
      this.needStopOnEnd = false;
      const currentAudioElement = this.getCurrentAudioElement();
      if (
        currentAudioElement &&
        (!currentAudioElement.paused || !currentAudioElement.ended)
      ) {
        currentAudioElement.pause();
      }
      this.$_stopRAFLoop();
      this.$_cleanStopAudioTimeout();
      this.clearPauseTimeout();
      this.$store.commit('PlaybackStore/setIsPlaying', false);
    },
    prev() {
      const prevParaId = this.getPrevParaId();
      this.pause();
      if (prevParaId) {
        this.playParagraph(prevParaId);
      } else {
        this.$store.dispatch('PlaybackStore/hideScrubber');
        this.$store.dispatch('PlaybackStore/pause');
      }
      return !!prevParaId;
    },
    next() {
      const nextParaId = this.getNextParaId();
      this.pause();
      if (nextParaId) {
        this.playParagraph(nextParaId);
      } else {
        this.$store.dispatch('PlaybackStore/hideScrubber');
        this.$store.dispatch('PlaybackStore/pause');
      }
      return !!nextParaId;
    },
    isChapter(locator) {
      const paraId = locator.startLocator.prefixedParagraphId;
      return this.$store.getters['BookStore/isChapter'](paraId);
    },
    getPauseDuration() {
      if (this.isAndroidBackground || this.isWebBackground) {
        return 0;
      }
      const currentTime = new Date().getTime();
      const scriptExecuteTime = currentTime - this.audioStopTime;
      const hasPauseMap = this.$store.getters['PublicationStore/hasPauseMap'];
      return hasPauseMap
        ? this.getNarratorPauseDuration(scriptExecuteTime)
        : this.getHtmlStructurePauseDuration(scriptExecuteTime);
    },
    getNarratorPauseDuration(scriptExecuteTime = 0) {
      const paraId = this.currentParaId;
      if (!paraId) {
        return 0;
      }
      const pauseDuration = this.$store.getters[
        'PublicationStore/getPauseMsByParaId'
      ](paraId);
      return Math.max(pauseDuration - scriptExecuteTime, 0);
    },
    getHtmlStructurePauseDuration(scriptExecuteTime = 0) {
      const { bookHasTrimmedSilence } = this.$store.getters[
        'PublicationStore/getMeta'
      ](this.publicationId);
      if (!bookHasTrimmedSilence) {
        return 0;
      }

      const nextParaId = this.getNextParaId();
      if (!nextParaId) {
        return 0;
      }
      const startLocator = new Locator.InTextLocator(nextParaId, 0);
      const currentStartLocator = new Locator.InTextLocator(
        this.currentParaId,
        0
      );
      const nextLocator = { startLocator };
      const pauseBetweenParagraphs = 1 * 1000;
      const pauseAfterChapter = 2.5 * 1000;
      const isCurrentParaChapter = this.isChapter({
        startLocator: currentStartLocator
      });
      let pauseDuration;
      const isNextParaChapter = this.isChapter(nextLocator);

      if (isCurrentParaChapter || isNextParaChapter) {
        pauseDuration = pauseAfterChapter;
      } else {
        pauseDuration = pauseBetweenParagraphs;
      }

      return Math.max(pauseDuration - scriptExecuteTime, 0);
    },
    getMeta() {
      return this.$store.getters['PublicationStore/getMeta'](
        this.publicationId
      );
    },
    setPauseTimeout(func, pauseMs) {
      this.timeoutBetweenParas = setTimeout(func, pauseMs);
    },
    clearPauseTimeout() {
      clearTimeout(this.timeoutBetweenParas);
    },
    ended() {
      // as requestAnimationFrame doesn't work in a background, we need an event
      // in order to play next paragraph
      if (this.isAndroidBackground || this.isWebBackground) {
        this.$_onEnd();
      }
    },
    onTimeUpdate() {
      if (!this.isAndroidBackground && !this.isWebBackground) {
        return;
      }
      const currentAudioElement = this.getCurrentAudioElement();
      if (!currentAudioElement) {
        return;
      }
      const currentTime =
        currentAudioElement.getCurrentTime() * AppConstants.MS_IN_SEC;
      if (this.initialTime <= currentTime) {
        this.$store.commit('PlaybackStore/setCurrentTime', currentTime);
      }
    },
    $_onEnd() {
      if (this.isAudioPaused) {
        this.$_stopRAFLoop();
        return;
      }
      const pauseMs = this.getPauseDuration();
      if (!pauseMs) {
        this.next();
      } else {
        this.setPauseTimeout(this.next.bind(this), pauseMs);
      }
    },
    $_startRAFLoop(startTime) {
      this.$_stopRAFLoop();
      const MAX_TIME_DIFF_PER_REQUEST = 1000;
      this.raf = window.requestAnimationFrame(() => {
        const currentAudioElement = this.getCurrentAudioElement();
        if (!currentAudioElement) {
          return;
        }
        const currentTime =
          currentAudioElement.getCurrentTime() * AppConstants.MS_IN_SEC;
        const delta = currentTime - startTime;
        if (
          (this.initialTime <= currentTime &&
            delta < MAX_TIME_DIFF_PER_REQUEST) ||
          !this.isIos
        ) {
          startTime = currentTime;
          this.$store.commit(
            'PlaybackStore/setCurrentTime',
            Math.max(currentTime, this.initialTime)
          );
        } else {
          logger.warn(
            `Get element current time out of expected range. delta:${delta} max delta value:${MAX_TIME_DIFF_PER_REQUEST}, initialTime:${this.initialTime} bigger than currentTime:${currentTime}`
          );
        }

        this.$_startRAFLoop(startTime);
      });
    },
    $_stopRAFLoop() {
      if (this.raf) {
        window.cancelAnimationFrame(this.raf);
        this.raf = null;
      }
    },
    $_getAlignmentEnd() {
      const alignmentIndex = this.alignmentIndex;
      const audioOffset = alignmentIndex[0];
      const alignmentEnd =
        audioOffset && audioOffset[audioOffset.length - 1]
          ? audioOffset[audioOffset.length - 1][1]
          : 0;
      return alignmentEnd;
    },
    audioPlayerErrorHandler(error) {
      this.$store.dispatch('PlaybackStore/pause');
      switch (error) {
        case CustomErrorEnum.NETWORK_ERROR:
          this.emit(AudioPlayerEvents.NETWORK_ERROR, { error });
          break;
        default:
          logger.fatal(`Error in AudioPlayer: ${error}`);
          this.emit(AudioPlayerEvents.ERROR, { error });
      }
    },
    emit(type, data = {}) {
      this.$emit('audioPlayerEvent', {
        type,
        data
      });
    },
    getPrevParaId() {
      const audioLinks = this.$store.getters[
        'PublicationStore/getAudioLinks'
      ]();
      const keys = Object.keys(audioLinks);
      const currentindex = keys.indexOf(this.currentParaId);
      return keys[currentindex - 1] || null;
    },
    getNextParaId() {
      if (this.playSinglePara) {
        return null;
      }
      const index = this.links.findIndex(
        link => link.getParaId() === this.currentParaId
      );
      return index !== -1 ? this.links[index + 1].getParaId() : null;
    }
  }
};
</script>
<style scoped>
audio {
  display: block;
  pointer-events: none;
}
</style>
