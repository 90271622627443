<template>
  <div class="login-state">
    <div v-if="showSocialIcon">
      <div class="text-center mt-6 mb-4">Continue via</div>
      <LoginButtonsWeb v-on="$listeners" />
      <div class="login-divider my-4">
        <v-divider /><span>or</span><v-divider />
      </div>
    </div>
    <div v-if="!hideTabs && !hiddenBeforeMount">
      <v-tabs v-model="activeTab.id" slider-size="4">
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: activeTab.id === tab.id }"
          @click="selectActiveTab(tab)"
        >
          {{ tab.label }}
        </v-tab>
      </v-tabs>
    </div>

    <component :is="activeTab.componentName" v-on="$listeners" />
  </div>
</template>

<script>
import LoginButtonsWeb from '@/components/views/LoginButtonsWeb/LoginButtonsWeb.vue';
import LoginByEmail from '@/components/views/LoginByEmail/LoginByEmail.vue';
import Registration from '@/components/views/Registration/Registration.vue';
import LoginStatesEnum from '@/enums/LoginStatesEnum';

const defaultState = LoginStatesEnum.LOGIN_BY_EMAIL;

export default {
  name: 'LoginState',
  components: {
    LoginButtonsWeb,
    LoginByEmail,
    Registration
  },
  props: {
    initState: {
      type: String,
      required: false,
      default: defaultState
    }
  },
  data() {
    const tabs = [
      {
        id: 0,
        label: this.$t('Login.Connect.tab.signIn'),
        componentName: LoginByEmail.name,
        state: LoginStatesEnum.LOGIN_BY_EMAIL
      },
      {
        id: 1,
        label: this.$t('Login.Connect.tab.signUp'),
        componentName: Registration.name,
        state: LoginStatesEnum.REGISTRATION
      }
    ];

    const defaultTab = tabs.find(tab => tab.state === defaultState);
    return {
      tabs,
      defaultTab,
      currentTab: null,
      hiddenBeforeMount: true
    };
  },
  computed: {
    hideTabs() {
      const isDevice = this.$store.getters['ContextStore/isDevice'];

      return isDevice && this.$store.getters['ContextStore/isPurchaseEnabled'];
    },
    showSocialIcon() {
      return !this.$store.getters['ContextStore/isElectron'];
    },
    activeTab: {
      get() {
        const tab = this.currentTab || this.getInitTab();
        return tab;
      },
      set(tab) {
        this.currentTab = tab;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.hiddenBeforeMount = false;
    });
  },
  methods: {
    getInitTab() {
      return (
        this.tabs.find(tab => tab.state === this.initState) || this.defaultTab
      );
    },
    selectActiveTab(tab) {
      this.activeTab = tab;
    }
  }
};
</script>

<style lang="less" src="./LoginState.less"></style>
